import React from 'react'
import { graphql } from 'gatsby'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import RichText from '~components/RichText'
import Image from '~components/Image'
import Seo from '~components/Seo'
import DataViewer from '~utils/DataViewer'
import Section from '~components/Section'
import { mobile, tablet } from '~styles/global'

const Page = ({ data }) => {
  const { title, content } = data.sanityPage

  return(
    <>
    <Seo 
      title={title}
      metaTitle={content?.seo?.metaTitle}
      description={content?.seo?.metaDescription}
      image={content?.seo?.socialImage}
    />
		<Section>
			<Container>
				<Heading>{title}</Heading>		
				<TextStyles className='small'><RichText content={content?.text}/></TextStyles>
			</Container>
		</Section>
    <DataViewer data={content} name="content"/>
    </>
  )
}

const Container = styled.div`
	grid-column: span 12;
	max-width: 655px;
	margin: 0 auto;
	margin-top: 61px;
	margin-bottom: 88px;
	${mobile}{
		margin-top: 27px;
		margin-bottom: 35px;
	}
`
const Heading = styled.h2`
	text-align: center;
	margin-bottom: 54px;
	${mobile}{
		margin-bottom: 26px;
	}
`
const TextStyles = styled.div`
	p{
		margin-bottom: 1.3em;
		:last-of-type{
			margin-bottom: 0;
		}
		${mobile}{
			margin-bottom: 1.5em;
			:last-of-type{
				margin-bottom: 0;
			}
		}
	}
	h4{
		font-family: 'Times Now', serif;
    font-size: 26px;
    line-height: 32px;
    letter-spacing: -0.01em;
    font-weight: 300;
		text-transform: inherit;
		margin-top: 34px;
    margin-bottom: 17px;
    ${tablet} {
      font-size: 18px;
      line-height: 22px;
    }
		${mobile}{
			margin-top: 25px;
    	margin-bottom: 14px;
		}
	}
	ul{
		margin-left: 29px;
		${mobile}{
			margin-left: 23px;
		}
		li{
			margin: 0;
		}
	}
`

export const query = graphql`
  query PageQuery($slug: String) {
    sanityPage(slug: {current: {eq: $slug}}) {
      title
      content: _rawContent
    }
  }
`

export default Page